import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import LayoutEt from "../../components/LayoutEt"
import HeaderEt from '../../components/HeaderEt'
import Seo from "../../components/Seo"

import Facebook from '../../images/icons/facebook.svg'
import Linkedin from '../../images/icons/linkedin.svg'
import Instagram from '../../images/icons/instagram.svg'
import Medium from '../../images/icons/medium.svg'

const Contact = () => {
  return (
    <LayoutEt>

      <Seo title="Kontakt" description="Puidust elementmajade tootja" lang="et" />
      <HeaderEt />
      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Kontakt</h1>
          <p className="body-l">Uus ehitusprojekt algamas
            Võta meiega ühendust ja vaatame koos, kuidas saame sinu projektile abiks olla.</p></div>
      </div>

      <div className="">
        <StaticImage
          src="../../images/contact-bg.jpg"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Prefab"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="grid mx-auto md:grid-cols-1 gap-12 w-3/4 lg:w-1/2 md:gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/team/welement.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welement"
                loading="eager"
                placeholder="blurred"
                className="w-32 rounded-full wel-mb-s"
              />
              <h3 className="title-s">Kontakt</h3>
              <p className="wel-mb-s">Sul on käsil ehitusprojekt ja soovid saada hinnapakkumist puitelementlahendusele? Võta meiega ühendust ja leiame koos parima lahenduse.</p>
              <a href="mailto:sales@welement.ee" className="btn btn-black wel-mb-xs">sales@welement.ee</a><br />
              <a href="tel:+37253334302" className="btn btn-black-outline">+372 5333 4302</a>
            </div>

          </div>

        </div>
      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="w-2/3 mx-auto wel-mb-l text-center">
            <h2 className="title wel-mb-s">Konsultatsioon ja koostöö</h2>
            <p className="body-l">Soovid kasutada puitu ja tehaselist elementehitust oma projektides? Võta ühendust ja selgitame, milline on kõige sobivam lahendust just sinu projektile
            </p>
          </div>

          <div className="grid mx-auto md:grid-cols-3 gap-12 w-3/4 md:w-full lg:w-3/4 md:gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/team/raiki.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Raiki"
                loading="eager"
                placeholder="blurred"
                className="w-32 rounded-full wel-mb-s"
              />
              <h3 className="title-s">Ermo-Raiki Rebane</h3>
              <p className="title-xs wel-mb-xs">COO</p>
              <a href="https://www.linkedin.com/in/ermo-raiki-rebane-16899811/" className="inline-block"><img src={Linkedin} alt="LinkedIn" className="h-6" /></a>
              <p className="wel-mb-s">Ühine meie meeskonnaga! Oleme pidevalt otsimas oma meeskonda andekaid talente. Kui oled huvitatud ehitusprotsessidest ja mõelnud, et kuidas seda võiks paremini teha, siis saada e-mail.
              </p>
              <a href="mailto:raiki@welement.ee" className="btn btn-black">E-mail</a>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/team/roomet.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Roomet"
                loading="eager"
                placeholder="blurred"
                className="w-32 rounded-full wel-mb-s"
              />
              <h3 className="title-s">Roomet Raig</h3>
              <p className="title-xs wel-mb-xs">Tootejuht</p>
              <a href="https://www.linkedin.com/in/roomet-raig-7a3abb88/" className="inline-block"><img src={Linkedin} alt="LinkedIn" className="h-6" /></a>
              <p className="wel-mb-s">Tahad rääkida tehase disainiprotsessist ja arhitektuuri optimeerimisest?
              </p>
              <a href="mailto:roomet@welement.ee" className="btn btn-black">E-mail</a>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/team/lauri.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Lauri"
                loading="eager"
                placeholder="blurred"
                className="w-32 rounded-full wel-mb-s"
              />
              <h3 className="title-s">Lauri Tuulberg</h3>
              <p className="title-xs wel-mb-xs">CEO</p>
              <a href="https://www.linkedin.com/in/laurituulberg/" className="inline-block"><img src={Linkedin} alt="LinkedIn" className="h-6" /></a>
              <p className="wel-mb-s">Arutame, kuhu ehitustööstus liigub, millised piirangud seab tehaseline ehitus arhitektuurile ja mis on kõige mõistlikum lahendus!

              </p>
              <a href="mailto:lauri@welement.ee" className="btn btn-black">E-mail</a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="w-2/3 mx-auto text-center">
            <h2 className="title wel-mb-m">Üldkontakt</h2>
          </div>

          <div className="text-center lg:text-left">
            <h3 className="title-s wel-mb-s">Welement AS</h3>
          </div>

          <div className="grid mx-auto text-center lg:text-left sm:grid-cols-2 lg:grid-cols-4 gap-8">

            <div>
              <p>Klaasi 14, 50409 Tartu<br />
                Eesti, EU</p>
              <a href="https://www.google.com/maps/place/WELEMENT+AS+Factory/@58.3955134,26.6743696,17z/data=!3m1!4b1!4m5!3m4!1s0x46eb365019cc3695:0x765e02a20cca6d7c!8m2!3d58.3955106!4d26.6765583" className="link">View on Map</a>
            </div>

            <div>
              <p>
                Tel: <a href="tel:+37253334302" className="link">+372 5333 4302</a><br />
                E-mail: <a href="mailto:info@welement.ee" className="link">info@welement.ee</a>
              </p>
            </div>

            <div>
              <p>
                Reg: 12892498<br />
                KMKR: EE101810982
              </p>
            </div>

            <div>
              <div className="wel-mb-s">
                <a href="https://www.linkedin.com/company/welement-as/" className="inline-block mr-1"><img src={Linkedin} alt="LinkedIn" className="h-10" /></a>
                <a href="https://www.facebook.com/welementprefab/" className="inline-block mr-1"><img src={Facebook} alt="Facebook" className="h-10" /></a>
                <a href="https://www.instagram.com/welement_prefab/" className="inline-block mr-1"><img src={Instagram} alt="Instagram" className="h-10" /></a>
                <a href="https://medium.com/welement" className="inline-block"><img src={Medium} alt="Medium" className="h-10" /></a>
              </div>
              <a href="https://forms.gle/tuFDPwqdMba1Q9j9A" className="btn btn-black-outline">Mailinglist</a>

            </div>

          </div>
        </div>
      </div>

    </LayoutEt>
  )

};

export default Contact;

